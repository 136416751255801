export enum CommentType {
  CommunityPost = 'CommunityComment',
  ProjectPost = 'ProjectComment',
  Discussion = 'ProjectDiscussion',
  CommunityDiscussion = 'CommunityDiscussion',
  ProgressUpdate = 'Paragraph',
  BusinessModelParagraph = 'BmParagraph',
  Request = 'Request',
  ApplicationEvaluation = 'ApplicationEvaluation',
  TabEntity = 'TabEntity',
}

export enum RequestStatusEnum {
  Open = 0,
  Assigned = 1,
  Closed = 2,
}

export enum StatsType {
  users = 'users',
  projects = 'projects',
  events = 'events',
  projectsPerProgram = 'projectsPerProgram',
  activitiesPerCircle = 'activitiesPerCircle',
  none = '',
}

export enum Planner {
  Cal = 0,
  Free = 1,
  Seq = 2,
  Stagegate = 3,
}

export enum PrivacyCircleType {
  project = 'Project circles',
  community = 'Community circles',
}

export enum NotificationFrequency {
  Never = 0,
  Immediate = 1,
  DailyDigest = 2,
  WeeklyDigest = 3,
}

export enum Flag {
  Idle = 0,
  Request = 1,
  Success = 2,
  Failure = 3,
}

export enum Response {
  Accept = 1,
  Deny = 2,
}

export enum Privacy {
  Public = 1,
  CommunityAdmin = 3,
  Team = 3,
  Custom = 4,
  TeamAndMentors = 5,
  TeamCanChoose = 6,
}

export enum EventUpdatesType {
  all = 'all',
  externalOnly = 'externalOnly',
  none = 'none',
}

export enum Languages {
  EN = 'en-EN',
  DE = 'de-DE',
  ES = 'es-ES',
  FR = 'fr-FR',
  IT = 'it-IT',
  PT = 'pt-PT',
  HR = 'hr-HR',
  RO = 'ro-RO',
  BG = 'bg-BG',
  GR = 'gr-GR',
  SR = 'sr-SR',
  TR = 'tr-TR',
  AR = 'ar-AR',
}

export enum GlobalAllOptions {
  GLOBAL = 'GLOBAL',
  ALL = 'All Industries',
}

export enum AggregationFunctionsEnum {
  SUM = 'sum',
  AVG = 'avg',
  NOOP = 'noop',
  COUNT = 'count',
}

export enum ChartTypesEnum {
  PIE = 'piechart',
  LINE = 'linegraph',
  BAR = 'barchart',
  NONE = '',
}

export enum TipState {
  Always = 0,
  Prevent = 1,
  Never = 2,
  PreventAndNever = 3,
}

export enum FormQuestionAnswerType {
  ProjectCoverAnswer = -2,
  ProjectLogoAnswer = -1,
  RadioChoiceAnswer = 0,
  CheckBoxChoiceAnswer = 1,
  DropDownChoiceAnswer = 2,
  MultipleChoiceAnswer = 3,
  AccountMoneyAnswer = 4,
  CalendarAnswer,
  ShortTextAnswer = 7,
  LongTextAnswer = 8,
  FileAnswer = 9,
  ScaleAnswer = 10,
  CountryAnswer = 11,
  TableAnswer = 12,
}

export enum ApplicationStatus {
  Apply = 0,
  Draft = 1,
  Pending = 2,
  Accepted = 3,
  Denied = 4,
}

export enum ImageType {
  UserProfile = 1,
  CommunityMenuLogoImage = 2,
  CommunityLogoImage = 3,
  ProjectLogo = 4,
  ProjectMainImage = 5,
  DiscussionImage = 6,
  CkEditorImage = 6,
  TabCover = 8,
}

export enum UserInvitationPolicy {
  AnyoneViaEmailOrSearch = 1,
  OnlyRegisteredUsers = 2,
  OnlyCommunityUsers = 3,
  NoInvitationsAdminOnly = 4,
}

export enum ModalSizeEnum {
  Mini = 'mini',
  Tiny = 'tiny',
  Small = 'small',
  Large = 'large',
  FullScreen = 'fullscreen',
}

export enum CalendarProvider {
  Google = 0,
  Outlook = 1,
}

export const AVAILABLE_MINUTES_DURATION = [15, 30, 45, 60];

export enum BookingType {
  DirectBooking = 0,
  FixedTimeslots = 1,
}

export enum MeetingProvider {
  MSTeams = 0,
  Zoom = 1,
}

export enum RequestType {
  ClassicRequest = 0,
  CoachingSession = 1,
}

export enum ViewType {
  List = 0,
  Card = 1,
}
