// Object containing all the literals used on the landing page

export const landingPageLiterals = {
  discussionOneFollower: '{0} started following',
  discussionTwoFollowers: '{0} and {1} started following',
  discussionManyFollowers: '{0} and {1} others started following',
  commentsOneCommented: '{0} Commented',
  commentsTwoCommented: '{0} and {1} Other User Commented',
  commentsManyCommented: '{0} and {1} Other Users Commented',
  buttonTextFollow: 'follow',
  searchbarPlaceholderMessage: 'Search',
  is_any_of: 'Is any of',
  is_none_of: 'Is none of',
  country: 'Country',
  project_edit_project_industry: 'Industry',
  project_list_project_sdg: 'SDGs',
  project_list_project_stage_placeholder: 'Phase',
  resource_edit_resource_tags: 'Tags',
  primitive_programs: 'Programs',
  clear_all_filters: 'Clear all filters',
  advanced_search_label: 'Advanced Filter',
  values: 'Values',
  landingWelcome: 'Welcome',
  landingNavigateTo: 'Navigate to',
  buttonTextExpand: 'Click here to expand',
  buttonTextShowLess: 'Click here to show less',
  buttonTextSignIn: 'Sign in to Post',
  buttonTextLogin: 'Login',
  buttonTextViewAll: 'View All',
  buttonTextCollapse: 'Show Less',
  buttontTextConnectTeam: 'Connect with the team',
  buttonTextBackToProjects: 'Back to list',
  landingDiscussionsTitle: 'Featured Conversations',
  landingRequestsTitle: 'Featured Requests',
  landingEventsTitle: 'Upcoming Events',
  landingNoEvents: 'No upcoming events',
  eventStartEndTime: 'Time {0} to {1}',
  landingMembers: 'Members',
  landingCompanies: 'Companies',
  landingNoMembers: 'No members',
  landingNoCompanies: 'No companies',
  landingCommunityTitle: 'Join the Community',
  landingCommunityBodyMessage: 'Join discussions, share your expertise, and connect with like-minded individuals',
  landingCommunityFooterMessage: 'Alternatively you can click {0} to create your account',
  here: 'here',
  tooltipLogin: 'Login to see more',
  userInfoAuthor: 'Author',
  userInfoPrivacy: 'Public',
  landingEntityDescription: 'Description',
  landingProjectDescription: 'Short Pitch',
  landingProjectInterestedInProject: 'Interested in this project?',
  landingProjectTeam: 'Team',
  loading: 'Loading',
  default_development_stage_idea: 'Idea',
  default_development_stage_prototype: 'Prototype',
  default_development_stage_start_up: 'Startup',
  default_development_stage_scaling: 'Growth',
  default_development_stage_maturity: 'Maturity',
} as const;
