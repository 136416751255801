import {
  User,
  Notification,
  MyStuffItem,
  NotificationSetting,
  SkillCategory,
  Country,
  SDG,
  Skill,
  MyStuffItemRequests,
  MyStuffItemDiscussions,
  MyStuff,
  ProjectCategory,
} from 'redux/types/account';
import { map } from 'ramda';
import { AxiosResponse } from 'axios';
import fileDownload from 'js-file-download';
import { sanitizeAssetUrl } from '../../util/assets';
import { uploadFile } from './file-upload';
import { getAxiosInstance } from './helper';
import { customSortArrayOfObjects, getInterestLinkFromNotification, getSDGIconPath } from '../../util/utils';
import { Languages, TipState, ImageType, GlobalAllOptions } from '../../redux/types/enums';
import moment from 'moment';

const ai = getAxiosInstance();

export function fetchNotifications(
  bearer: string,
  take: number,
  skip: number,
  concept: string = '',
): Promise<{
  notifications: Notification[];
  skip: number;
}> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/UserNotification/GetUserNotifications',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: {
        take,
        skip,
        onlyUnread: false,
        orderBy: 1,
        concept,
      },
    })
      .then(response => {
        resolve({
          skip,
          notifications: map(
            item => ({
              id: item.id,
              isRead: item.isRead,
              content: item.notificationContentV2,
              date: item.notificationCreationDate,
              interestLink: getInterestLinkFromNotification(item.notificationContentV2),
            }),
            response.data.list,
          ),
        });
      })
      .catch(err => reject(err));
  });
}

export function markNotificationAsRead(bearer: string, id: number): Promise<{ id: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/UserNotification/SetIsRead?id=${id}&isRead=true`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => resolve({ id }))
      .catch(err => reject(err));
  });
}

export function markAllNotificationsAsRead(bearer: string): Promise<null> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/UserNotification/MarkAllAsRead',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => {
        resolve(null);
      })
      .catch(err => reject(err));
  });
}

export const _mapResponseToUser = (data: any): User => {
  return {
    isCalendarSynchronized: data.isCalendarSynchronized,
    synchronizedCalendarAccount: data.synchronizedCalendarAccount,
    synchronizedCalendarType: data.synchronizedCalendarType,
    hasMeetingProvider: data.hasMeetingProvider,
    meetingProviderAccount: data.meetingProviderAccount,
    meetingProviderType: data.meetingProviderType,
    rawUser: data,
    id: data.id,
    name: data.name,
    photo: sanitizeAssetUrl(data.photo),
    email: data.mail,
    firstName: data.firstName,
    lastName: data.lastName,
    admin: data.isPlatformAdmin,
    active: data.isActive,
    banned: data.isBanned,
    occupation: data.occupation,
    profile: {
      biography: data?.biography || '',
      marketExpertises: data.marketExpertises || [],
      sustainableDevelopmentGoals: data.sustainableDevelopmentGoals || [],
      skills: data.skills
        .filter((skill: Skill) => skill !== null)
        .map((skill: Skill) => ({ id: skill.id, name: skill.name })),
      interests: data.interests.map((interest: any) => ({ id: interest.id, name: interest.name })),
    },
    profileWizardDone: data.isProfileWizardDone,
    countryId: data.countryId,
    countryName: data.country?.name,
    city: data.city,
    language: data.language,
    primaryCommunityId: data.primaryCommunityId,
    notificationActive: data.notificationEnabled,
    registrationDate: data.registrationDate,
    lastLogin: data.lastLoginDate,
    ownedProjects: [],
    followedProjects: [],
    communities: [],
    requests: [],
    settings: {
      notifications: {
        enabled: data.notificationEnabled,
        list: [],
      },
    },
    pendingRequestIds: data.pendingRequestIds,
    company: data.company,
    redirectAfterFirstLogin: data.redirectAfterFirstLogin,
  };
};

export function reacceptTermsAndConditions(bearer: string): Promise<User> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'PUT',
      url: '/api/User/AcceptTermsAndConditions',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve(_mapResponseToUser({ ...data }));
      })
      .catch(err => reject(err));
  });
}

export function fetchCurrentUserData(bearer: string): Promise<User> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/User/GetCurrent',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve(_mapResponseToUser(data));
      })
      .catch(err => {
        if (err?.response?.status === 500) {
          if (
            err?.response?.data ===
            'Babele.Domain.Aggregates.UserAgg.Exceptions.UserMustAcceptNewTermsAndConditionsException'
          ) {
            if ((window as any).navigateToReacceptTerms) {
              (window as any).navigateToReacceptTerms();
            }
          }
        }
        reject(err);
      });
  });
}

const mapToItem = (c: any, owner: boolean = false): MyStuffItem => ({
  id: c.id,
  name: c.name,
  logo: sanitizeAssetUrl(c.logoImage),
  description: c.shortDescription || c.description,
  owner,
  communityIds: c.communityIds,
  circleName: c.circleName,
});

export function fetchMyStuff(bearer: string): Promise<MyStuff> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/User/GetMyStuff',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve({
          projects: data.projects.map((p: any) => mapToItem(p, true)),
          followedProjects: [],
          communities: data.communities.map((c: any) => mapToItem(c)),
          followedDiscussions: data.followedDiscussions.map(
            (d: any): MyStuffItemDiscussions => ({
              id: d.id,
              entityType: d.communityId ? 'community' : 'project',
              entityId: d.communityId ? d.communityId : d.projectId,
              name: d.communityId ? d.communityName : d.communityId,
              logoImage: d.communityId ? d.communityLogoImage : d.projectLogoImage,
            }),
          ),
          requests: data.requests.map(
            (r: any): MyStuffItemRequests => ({
              ...r,
              reviews: map(review => {
                return {
                  id: review.id,
                  advisorRating: {
                    userId: review.advisorId,
                    userName: `${review.advisorFirstName} ${review.advisorLastName}`,
                    userPhoto: review.advisorPhotoUrl,
                    rating: review.advisorRating,
                    comment: review.advisorComment,
                    date: moment(review.advisorFeedbackDatetime),
                  },
                  entrepreneurRating: {
                    userId: review.entrepreneurId,
                    userName: `${review.entrepreneurFirstName} ${review.entrepreneurLastName}`,
                    userPhoto: review.entrepreneurPhotoUrl,
                    rating: review.entrepreneurRating,
                    comment: review.entrepreneurComment,
                    date: moment(review.entrepreneurFeedbackDatetime),
                  },
                };
              }, r.requestFeedbacks || []),
            }),
          ),
        });
      })
      .catch(err => reject(err));
  });
}

export function downloadPersonalInfo(bearer: string): Promise<null> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/UserPersonalInformation/RequestPersonalInformation',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      responseType: 'arraybuffer',
    })
      .then(response => {
        fileDownload(response.data, 'personal-data.zip', 'application/zip');
        resolve(null);
      })
      .catch(err => reject(err));
  });
}

export function resetPassword(bearer: string, oldPassword: string, newPassword: string, userId: number): Promise<null> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/User/ChangePassword',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: {
        currentPassword: oldPassword,
        password: newPassword,
        id: userId,
      },
    })
      .then(() => resolve(null))
      .catch(err => reject(err));
  });
}

export function changeAccountEmail(
  bearer: string,
  oldEmail: string,
  newEmail: string,
  password: string,
): Promise<null> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/User/EditMyEmail',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: {
        currentEmail: oldEmail,
        email: newEmail,
        password: password,
      },
    })
      .then(() => resolve(null))
      .catch(err => reject(err));
  });
}

export function disableAccount(bearer: string): Promise<null> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/User/DisableMe',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => resolve(null))
      .catch(err => reject(err));
  });
}

export function fetchNotificationSettings(bearer: string, userId: number): Promise<NotificationSetting[]> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/UserNotificationSetting/GetUserNotificationSettings',
      params: {
        id: userId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(response => {
        const settings: NotificationSetting[] = response.data.map((item: any) => ({
          id: item.id,
          description: item.description,
          notificationType: item.notificationType,
          notificationFrequency: item.notificationFrequency,
          category: item.category,
        }));
        resolve(settings);
      })
      .catch(err => reject(err));
  });
}

export function saveNotificationSetting(
  bearer: string,
  userId: number,
  settings: NotificationSetting[],
): Promise<NotificationSetting[]> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/UserNotificationSetting/SaveUserNotificationSettings',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: settings.map(s => ({ ...s, userId })),
    })
      .then(() => resolve(settings))
      .catch(err => reject(err));
  });
}

export function toggleNotifications(bearer: string, state: boolean): Promise<{ state: boolean }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/UserNotificationSetting/EnableOrNotNotifications',
      headers: {
        Authorization: `Bearer ${bearer}`,
        'Content-Type': 'application/json; charset=utf-8',
      },
      data: state.toString(),
    })
      .then(() => resolve({ state }))
      .catch(err => reject(err));
  });
}

export function fetchStandardInterestList(bearer: string): Promise<any[]> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Interest/GetStandardList',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(response =>
        resolve(response.data.sort((categoryA: any, categoryB: any) => categoryA.name.localeCompare(categoryB.name))),
      )
      .catch(err => reject(err));
  });
}

export function fetchStandardSkillList(bearer: string): Promise<SkillCategory[]> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/SkillCategory/GetCategories',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(response => {
        // resolve(response.data);
        resolve(
          response.data.map(
            (skill: SkillCategory): SkillCategory => ({
              ...skill,
              skills: skill.skills.sort((skillA: Skill, skillB: Skill) => skillA.name.localeCompare(skillB.name)),
              img: `/static/skill-categories/${skill.name.replace(/\W/g, '')}.jpg`,
            }),
          ),
        );
      })
      .catch(err => reject(err));
  });
}

export function updateSkillFetch(bearer: string, data: any): Promise<any> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/SkillCategory/Save',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data,
    })
      .then(response => {
        resolve(response.data);
      })
      .catch(err => reject(err));
  });
}

export function saveProfile(
  bearer: string,
  rawUser: any,
  userId: number,
  country: number,
  city: string,
  role: string,
  biography: string,
  file: File | null,
  photo: string,
  firstName: string,
  lastName: string,
  interests: number[],
  skills: number[],
  language: Languages,
  marketExpertises: Country[],
  sustainableDevelopmentGoals: SDG[],
  primaryCommunityId: number,
  company: string,
  linkedInUrl?: string,
): Promise<User> {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let data = {
      ...rawUser,
      id: userId,
      countryId: country,
      city,
      occupation: role,
      language,
      photo,
      biography,
      firstName,
      lastName,
      isSubscribedToExternalMail: false,
      skills: skills.map(skill => ({ id: skill })),
      interests: interests.map(interest => ({ id: interest })),
      marketExpertises,
      sustainableDevelopmentGoals,
      primaryCommunityId,
      company,
    };
    if (linkedInUrl) data.linkedInUrl = linkedInUrl;

    let profilePhoto: { id: number; link: string } | undefined;

    if (file) {
      profilePhoto = await uploadFile(bearer, { file, folder: 'profile_picture' }, ImageType.UserProfile);
    }

    if (profilePhoto) {
      data = {
        ...data,
        photo: sanitizeAssetUrl(profilePhoto.link),
      };
    }

    ai({
      method: 'POST',
      url: '/api/User/SaveProfile',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data,
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve({
          id: data.id,
          name: data.name,
          photo: sanitizeAssetUrl(data.photo),
          email: data.mail,
          admin: data.isPlatformAdmin,
          active: data.isActive,
          banned: data.isBanned,
          occupation: data.occupation,
          profile: {
            biography: data.biography,
            skills: data.skills.map((s: any) => ({ id: s.id, name: s.name })),
            interests: data.interests.map((i: any) => ({ id: i.id, name: i.name })),
            marketExpertises: data.marketExpertises || [],
            sustainableDevelopmentGoals: data.sustainableDevelopmentGoals || [],
          },
          profileWizardDone: data.isProfileWizardDone,
          countryId: data.countryId,
          city: data.city,
          language: data.language,
          primaryCommunityId: data.primaryCommunityId,
          notificationActive: data.notificationEnabled,
          registrationDate: data.registrationDate,
          lastLogin: data.lastLoginDate,
          ownedProjects: [],
          followedProjects: [],
          communities: [],
          requests: [],
          linkedInUrl,
          company,
          settings: {
            notifications: {
              enabled: data.notificationEnabled,
              list: [],
            },
          },
        });
      })
      .catch(err => reject(err));
  });
}

export function changeTipState(
  tip: string,
  state: TipState,
): Promise<{
  tip: string;
  state: TipState;
}> {
  return new Promise((resolve, reject) => {
    resolve({
      tip,
      state,
    });
  });
}

export function clearHasPendingRequests(bearer: string): Promise<null> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'PUT',
      url: `/api/User/ClearHasPendingRequests`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => resolve(null))
      .catch(err => reject(err));
  });
}

export function fetchCountries(bearer: string, loadGlobalOption?: boolean): Promise<Country[]> {
  let url = `/api/Country/GetAll`;

  if (typeof loadGlobalOption === 'undefined') {
    url += '?loadGlobalOption=false';
  } else {
    url += `?loadGlobalOption=${loadGlobalOption}`;
  }

  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) =>
        resolve(customSortArrayOfObjects(response.data, 'name', GlobalAllOptions.GLOBAL)),
      )
      .catch(err => reject(err));
  });
}

export function fetchSDGs(bearer: string): Promise<SDG[]> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/SustainableDevelopmentGoal/Get',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve(
          response.data.map((sdg: SDG) => ({
            ...sdg,
            iconName: getSDGIconPath(sdg.iconName),
          })),
        );
      })
      .catch(err => reject(err));
  });
}

export function fetchUserRequestsPendingFeedback(bearer: string): Promise<Request[]> {
  const now = Date.now();
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/User/requests-pending-feedback',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve(
          response.data.filter((val: any) => {
            if (!val.endDate) return true;
            const now = moment();
            const endDate = moment(val.endDate);
            return endDate.isBefore(now);
          }),
        );
      })
      .catch(err => reject(err));
  });
}

export function fetchProjectCategories(bearer: string, loadAllOption = false): Promise<ProjectCategory[]> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Category/GetStandardList',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        const sorted = customSortArrayOfObjects(response.data, 'name', GlobalAllOptions.ALL);

        if (!loadAllOption) {
          return resolve(sorted.filter(option => option.name !== 'All'));
        }

        return resolve(sorted);
      })
      .catch(err => reject(err));
  });
}

export function resetRedirectUrl(bearer: string): Promise<void> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/User/ResetCurrentuserFirstRedirectUrl',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve(response.data);
      })
      .catch(err => reject(err));
  });
}

export default {
  fetchUserRequestsPendingFeedback,
  fetchNotifications,
  markNotificationAsRead,
  markAllNotificationsAsRead,
  fetchCurrentUserData,
  fetchMyStuff,
  downloadPersonalInfo,
  resetPassword,
  disableAccount,
  fetchNotificationSettings,
  saveNotificationSetting,
  toggleNotifications,
  fetchStandardInterestList,
  fetchStandardSkillList,
  saveProfile,
  changeTipState,
  reacceptTermsAndConditions,
  clearHasPendingRequests,
  fetchCountries,
  fetchSDGs,
  fetchProjectCategories,
};
